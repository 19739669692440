

















































































































































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import { GET_ALL_SYNONYMS } from '@/store/user/actions.type'
import { Qualification } from '@/store/types'
import InfoBox from '@/components/hszg/InfoBox.vue'
import ConfirmationModal from '@/components/common/ConfirmationModal.vue'
import { hasRight } from '@/utils/rights'
import Rights from '@/common/rights'
import { EDIT_SYNONYM, GROUP_SYNONYM } from '@/store/employee/actions.type'
import LoadingSpinner from '@/components/common/LoadingSpinner.vue'

@Component({
  components: { LoadingSpinner, ConfirmationModal, InfoBox }
})
export default class QualificationsAdministration extends Vue {
  @Action(GET_ALL_SYNONYMS)
  public getAllSynonyms: () => Promise<Array<Qualification>>

  @Action(GROUP_SYNONYM)
  public groupSynonym: (synonym: Qualification) => Promise<Qualification>

  @Action(EDIT_SYNONYM)
  public editSynonym: (synonym: Qualification) => Promise<Qualification>

  synonyms: Qualification[] = []
  filteredSynonyms: Qualification[] = []
  loadingSynonyms = false
  dragging = false
  draggable = true
  draggedGroup = ''
  filterText = ''
  editing: Qualification | null = null
  editInput = ''

  mounted (): void {
    this.loadSynonyms()
  }

  loadSynonyms (): void {
    this.loadingSynonyms = true
    this.getAllSynonyms().then(data => {
      this.synonyms = data
      this.filteredSynonyms = this.synonyms
    }).finally(() => {
      this.loadingSynonyms = false
    })
  }

  get synonymGroups (): (string | undefined)[] {
    return [...new Set(this.filteredSynonyms.map(sy => sy.groupId))]
  }

  filter (e: Event): void {
    this.filteredSynonyms = this.synonyms.filter(item => {
      if (item.synonym) {
        if (item.synonym.toLowerCase().indexOf(e.toString().toLowerCase()) !== -1) return true
      }
    })
  }

  startDrag (e: DragEvent, synonym: Qualification): void {
    if (e.dataTransfer) {
      e.dataTransfer.dropEffect = 'move'
      e.dataTransfer.effectAllowed = 'move'
      e.dataTransfer.setData('synonymId', synonym.synonymId ? synonym.synonymId : '')
      if (synonym.groupId) this.draggedGroup = synonym.groupId
      this.dragging = true
      this.$root.$emit('dismiss-alert')
    }
  }

  onDrop (e: DragEvent, where: string): void {
    this.draggedGroup = ''
    if (e.dataTransfer) {
      const synonymId = e.dataTransfer.getData('synonymId')
      const synonym = this.synonyms.find(sy => sy.synonymId === synonymId)
      const synonymToGroup: Qualification = {
        synonymId: synonymId,
        synonym: this.synonyms.find(sy => sy.synonymId === synonymId)?.synonym,
        groupId: this.synonyms.find(sy => sy.synonymId === synonymId)?.groupId,
        value: ''
      }

      if (where === 'new' && synonym) {
        // send synonym to backend with no groupId
        this.draggable = false
        synonymToGroup.groupId = undefined
        this.$root.$emit('load')
        this.groupSynonym(synonymToGroup).then(data => {
          const newSynonym = data
          this.synonyms.unshift(newSynonym)
          this.synonyms.splice(this.synonyms.lastIndexOf(synonym), 1)
          this.filterText = ''
          this.filteredSynonyms = this.synonyms
        }).catch(() => {
          this.$root.$emit(
            'alert',
            this.$i18n.t('qualifications-administration.new-group-error'),
            this.$i18n.t('qualifications-administration.new-group-error-text', { what: synonym.synonym }),
            true
          )
        }).finally(() => {
          this.draggable = true
          this.$root.$emit('end-load')
        })
      } else if (where === 'edit' && synonym) {
        this.editing = synonym
        if (synonym.synonym) this.editInput = synonym.synonym
      } else {
        // send synonym to backend with new groupId
        if (synonym && synonym.groupId !== where) {
          synonymToGroup.groupId = where
          this.draggable = false
          this.$root.$emit('load')
          this.groupSynonym(synonymToGroup).then(data => {
            this.$set(this.synonyms, this.synonyms.lastIndexOf(synonym), data)
            this.filterText = ''
            this.filteredSynonyms = this.synonyms
          }).catch(() => {
            this.$root.$emit(
              'alert',
              this.$i18n.t('qualifications-administration.group-error'),
              this.$i18n.t('qualifications-administration.group-error-text', { what: synonym.synonym }),
              true
            )
          }).finally(() => {
            this.draggable = true
            this.$root.$emit('end-load')
          })
        }
      }
      this.dragging = false
    }
  }

  cancelEdit (): void {
    this.editInput = ''
    this.editing = null
  }

  saveEdit (): void {
    // send edited synonym to backend
    if (this.editing) {
      const synonymToEdit: Qualification = { synonymId: this.editing.synonymId, synonym: this.editInput, groupId: this.editing.groupId, value: '' }

      // if another qualification will be alias
      if (this.alias) {
        synonymToEdit.aliasId = this.alias.synonymId
        synonymToEdit.groupId = this.alias.groupId
      }

      this.draggable = false
      this.$root.$emit('load')
      this.editSynonym(synonymToEdit).then(data => {
        if (!this.alias) {
          // update selected synonym
          this.$root.$emit(
            'alert',
            this.$i18n.t('qualifications-administration.edit-success'),
            this.$i18n.t('qualifications-administration.edit-success-text', { what: this.editing?.synonym, what2: synonymToEdit.synonym })
          )
          if (this.editing) this.editing.synonym = data.synonym
        } else {
          // delete old synonym - alias is set
          this.$root.$emit(
            'alert',
            this.$i18n.t('qualifications-administration.align-success'),
            this.$i18n.t('qualifications-administration.align-success-text', { what: this.editing?.synonym, what2: synonymToEdit.synonym })
          )
          this.synonyms.splice(this.synonyms.findIndex(sy => sy === this.editing), 1)
        }
        this.filterText = ''
        this.filteredSynonyms = this.synonyms
      }).catch(() => {
        this.$root.$emit(
          'alert',
          this.$i18n.t('qualifications-administration.edit-error'),
          this.$i18n.t('qualifications-administration.edit-error-text',
            { what: this.editing?.synonym }),
          true
        )
      }).finally(() => {
        this.draggable = true
        this.cancelEdit()
        this.$root.$emit('end-load')
      })
    }
  }

  deleteQualification (confirmationNeeded: boolean) {
    if (confirmationNeeded) this.$bvModal.show('confirmation-modal-delete-qualification')
    else {
      if (this.editing) {
        this.draggable = false
        const synonymToDelete: Qualification = { synonymId: this.editing.synonymId, synonym: undefined, groupId: this.editing.groupId, value: '' }
        this.$root.$emit('load')
        this.editSynonym(synonymToDelete).then(() => {
          this.synonyms.splice(this.synonyms.findIndex(sy => sy === this.editing), 1)
          this.filterText = ''
          this.filteredSynonyms = this.synonyms
          this.$root.$emit(
            'alert',
            this.$i18n.t('qualifications-administration.delete-success'),
            this.$i18n.t('qualifications-administration.delete-success-text', { what: this.editing?.synonym })
          )
        }).catch(() => {
          this.$root.$emit(
            'alert',
            this.$i18n.t('qualifications-administration.delete-error'),
            this.$i18n.t('qualifications-administration.delete-error-text', { what: this.editing?.synonym }),
            true
          )
        }).finally(() => {
          this.draggable = true
          this.cancelEdit()
          this.$root.$emit('end-load')
        })
      }
    }
  }

  get hasEditRight (): boolean {
    return hasRight(Rights.SYNONYM_EDIT)
  }

  get relatedSynonyms (): Qualification[] {
    if (this.editInput) return this.synonyms.filter(sy => sy.synonym !== this.editing?.synonym && sy.synonym?.toLowerCase().indexOf(this.editInput.toLowerCase()) !== -1)
    else return []
  }

  get alias (): Qualification | undefined {
    return this.synonyms.filter(syn => syn.synonymId !== this.editing?.synonymId).find(sy => sy.synonym === this.editInput)
  }
}
